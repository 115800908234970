/**
* Generated automatically at built-time (2024-11-13T10:38:50.995Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ideen-outdoorkueche",templateKey: "sites/105-372c7596-0489-4fe3-a7ce-4eefa1829017"};